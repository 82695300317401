html {
  color: white;
  background-color: #1E0056;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#surveyPrompt {
  margin-top: 3vh;
  font-size: 10vh;
}

.survey-btn {
  background-color: #1E0056;
  background-color: white;
  display: block;
  width: 70vw;
  height: 10vh;
  border: black 0px solid;
  border-radius: 3px;
  font-size: 3vh;
  margin: auto;
  margin-bottom: 4vh;
}

.survey-btn:focus {
  background-color: #3A3A3A;
  color: white;
  border: none;
}

.closed-tab {
  display: inline;
  width: 5vw;
  margin: 25px;
}
