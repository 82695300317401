.main-tab {
    display: inline;
    width: 100px;
    height: 100vh;
    float: left;
    z-index: -99;
}

.active-tab {
    width: 60vw;
    display: inline-block;
    background-color: blue;
    z-index: -99;
}

.active-tab .tab-title {
    font-size: 5vh;
    margin-top: 5vh;
}

.inactive-tab {
    padding: 0px;
    max-width: 6vw;
}

.inactive-tab .tab-title {
    writing-mode: vertical-rl; 
    font-size: 2vw;
    margin-top: 2vh;
}

.inactive-tab .tab-content {
    display: none;
}

.modal {
    z-index: 99;
    height: 100vh;
    width: 100vw;
    opacity: 0.8;
}